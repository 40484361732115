import React, { ReactNode } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '@travel/traveler-core/constants/styles';
import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';
import { cx } from '@travel/utils';

import Media from 'components/Media';

import { getHeight, getIsSmartBannerDisplay } from 'store/banner/selectors';

import ric from 'utils/ric';

import styles from './heroImage.module.scss';

type Image = {
  /** String of image url */
  url?: string;
  /** String of image url */
  alt?: string;
};

type Props = {
  className?: string;
  /** Type to define the page name to be displayed */
  page: 'Top' | 'Area';
  /** Children component to be appended to Tagline */
  children?: ReactNode;
  /** Flag to define whether is tagline data is being fetched */
  isTaglineFetching?: boolean;
  /** Array of image object */
  images: Array<Image>;
};

function HeroImage(props: Props) {
  const isTop = props.page === 'Top';
  const isSP = useDeviceType() === MOBILE_SCREEN;

  const hasSmartBanner = useSelector(getIsSmartBannerDisplay);
  const bannerHeight = useSelector(getHeight);

  const initialHeroImage = props.images?.find(item => item.url);
  const srcSetImages = ric.srcset(initialHeroImage?.url, {
    width: isSP ? MOBILE_MIN_WIDTH : TABLET_MIN_WIDTH,
  });

  return (
    <div
      data-testid="heroImage-wrapper"
      className={cx(
        styles.container,
        props.className,
        isSP && hasSmartBanner && styles.withSmartBanner,
      )}
    >
      {/* Hero image */}
      {/* TIDP-6494 removed multiple images(HomeImageSlider) on top page. Will be re-added in future */}
      <div
        className={styles.mediaWrapper}
        style={{ height: `calc(100% - ${bannerHeight}px)` }}
        data-testid="heroImage-mediaWrapper"
      >
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={ric.resize(initialHeroImage?.url)}
            // @ts-ignore; will be available in @types/react@^17
            imagesrcset={srcSetImages}
            imagesizes="100vw"
            fetchpriority="high"
          />
        </Helmet>
        <Media
          sizes="100vw"
          srcsetStartWidth={isSP ? MOBILE_MIN_WIDTH : TABLET_MIN_WIDTH}
          isMediaOnly={true}
          className={isTop ? styles.media : ''}
          media={{
            url: initialHeroImage?.url,
          }}
          isDisableLazyload={true}
          fetchPriority="high"
        />
        {!isTop && <div className={styles.gradient} />}
      </div>
    </div>
  );
}

HeroImage.defaultProps = {
  page: 'Top',
};

export default HeroImage;
